import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/layout";
import Seo from "../components/seo";
import News from "../components/news";
import Events from "../components/events";
import Header from "../components/header";

// import mainAd from "../images/general/ads/costa-ballena.png"

const Index = ({ pageContext: { pageInfo } }) => {
  const mainBanner = pageInfo.banner;
  const news = pageInfo.news;
  const events = pageInfo.events;

  return (
    <Layout>
      <Seo title="Inicio" />
      <Header location="/" bannerInfo={mainBanner} news={news} />
      <main className="home">
        <div className="cenfarte-info">
          <p>
            El Centro Farmacéutico del Norte (Cenfarte) fue fundado en 1942 con
            la determinación de mejorar y optimizar el abastecimiento de
            medicamentos a las farmacias. Actualmente, el grupo cuenta con más
            de 100 empleados, 1000 socios y una red próxima a las 4000
            farmacias.
          </p>
        </div>
        <div className="ad-01">
          <iframe
            src="https://player.vimeo.com/video/830191046?autoplay=1&muted=1"
            frameBorder="0"
            style={{ height: 280, width: "100%" }}
            allow="autoplay; fullscreen"
            autoPlay="1&loop=1&autopause=0"
            allowFullScreen
            title="Vídeo de Cenfarte"
          />
        </div>
        <div className="news-container">
          <div className="news-titles">
            <h5>Últimas noticias</h5>
          </div>
          <News postsList={news} />
          <Events eventList={events} />
        </div>
      </main>
    </Layout>
  );
};

export default Index;
